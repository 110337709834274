import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, Button, IconButton, Toolbar, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Users as UsersIcon } from 'react-feather';
import Account from './Account';
import useAuth from '../../../hooks/useAuth';
import { Patient } from 'src/types/patient';

interface TopBarProps {
    className?: string;
    onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme) => ({

    root: {
        zIndex: theme.zIndex.drawer + 100,
        boxShadow: 'theme.shadows[16]',
        backgroundColor: '#ffffff'
    },
    toolbar: {
        minHeight: 64
    },
    logInButton: {
        marginRight: '1rem'
    }
}));

const TopBar: FC<TopBarProps> = ({
    className,
    onMobileNavOpen,
    ...rest
}) => {
    const classes = useStyles();
    const { isAuthenticated } = useAuth();
    const logo = window.localStorage.getItem('organization_logo')
    const patient: Patient = JSON.parse(localStorage.getItem('user'));

    return (
        <AppBar
            className={clsx(classes.root, className)}
            sx={{ display: 'block', displayPrint: 'none' }}

            {...rest}
        >
            <Toolbar className={classes.toolbar}>

                {/* Hidden in Large screens -> Visible in Smarthphones */}
                <Hidden lgUp>
                    {isAuthenticated && (
                        <>
                            <IconButton color="primary" size="large">
                                <img
                                    style={{height: '1.65rem'}}
                                    alt="Logo"
                                    src={logo}
                                />
                            </IconButton>
                            <Box
                                ml={2}
                                flexGrow={1}
                            />
                            <Box ml={2}>
                                <Account />
                            </Box>
                        </>
                    )}
                    {!isAuthenticated && (
                        <>
                            <IconButton color="primary" size="large">
                                <img
                                    style={{height: '1.65rem'}}
                                    alt="Logo"
                                    src={logo}
                                />
                            </IconButton>

                            <Box
                                ml={2}
                                flexGrow={1}
                            />

                            <Button
                                color="secondary"
                                variant="outlined"
                                component={RouterLink}
                                to="/"
                                startIcon={
                                    <SvgIcon fontSize="small">
                                        <UsersIcon />
                                    </SvgIcon>
                                }
                                className={classes.logInButton}
                            >
                                Entrar
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                component={RouterLink}
                                to="/register"
                            >
                                Registrarse
                            </Button>
                        </>
                    )}
                </Hidden>

                {/* Hidden in smartPhone -> Visible in desktop computers */}
                <Hidden lgDown>
                    <RouterLink to="/app/dashboard">
                        <img
                            style={{height: '1.65rem'}}
                            alt="Logo"
                            src={logo}
                        />
                    </RouterLink>
                    <Box
                        ml={2}
                        flexGrow={1}
                    />

                    {isAuthenticated && (
                        <>
                            <Box ml={2}>
                                <Account />
                            </Box>
                        </>
                    )}
                    {!isAuthenticated && (
                        <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                component={RouterLink}
                                to="/"
                                startIcon={
                                    <SvgIcon fontSize="small">
                                        <UsersIcon />
                                    </SvgIcon>
                                }
                                className={classes.logInButton}
                            >
                                Entrar
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                component={RouterLink}
                                to="/register"
                            >
                                Registrarse
                            </Button>
                        </>
                    )}
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string
};

export default TopBar;
